import 'react-toastify/dist/ReactToastify.css'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

const HomePage = () => {

  const router = useRouter();
  useEffect(() => { router.push('/home') }, []);

  return (
    <></>
  )
}

HomePage.accessType = 'authenticated';

export default HomePage;
